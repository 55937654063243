/*=============================================
=            checkout            =
=============================================*/

.checkout-form {
  padding: 40px 20px;

  background-color: $white;
  &__single-field {
    label {
      font-size: 14px;

      margin-bottom: 20px;

      color: $black--two;
    }
    input,
    textarea {
      font-size: 18px;

      display: block;

      width: 100%;
      padding-bottom: 10px;

      color: $black--two;
      border: none;
      border-bottom: 2px solid $grey--eight;

      &:focus {
        border-bottom: 2px solid $theme-color--default;
      }
    }

    ::placeholder {
      /* Firefox */
      opacity: 1;

      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: $grey--four;
    }

    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: $grey--four;
    }

    ::-ms-input-placeholder {
      /* Microsoft Edge */
      color: $grey--four;
    }
  }

  &__helper_text {
    font-size: 12px;
  }

  &__invalid_field {
    input,
    textarea {
      border-bottom: 2px solid $red--two;
    }

    .checkout-form__helper_text {
      color: $red--two;
    }
  }

  &__button {
    font-size: 16px;
    line-height: 1;

    width: 100%;
    padding: 15px 0;

    color: $white;
    border: none;
    background: none;
    background-color: $theme-color--default;
  }
}

.your-order-area {
  h3 {
    font-size: 20px;
    font-weight: 500;

    margin: 0 0 20px;

    color: $black;
  }
  .your-order-wrap {
    padding: 30px 15px;

    background: $grey--seven;

    .your-order-product-info {
      .your-order-top {
        ul {
          display: flex;
          justify-content: space-between;
          li {
            font-size: 16px;
            font-weight: 500;

            list-style: outside none none;

            color: $black;
          }
        }
      }
      .your-order-middle {
        margin: 29px 0;
        padding: 19px 0 18px;

        border-top: 1px solid $grey--eight;
        border-bottom: 1px solid $grey--eight;
        ul {
          li {
            display: flex;
            justify-content: space-between;

            margin: 0 0 10px;
          }
        }
      }
      .your-order-bottom {
        ul {
          display: flex;
          align-items: center;
          justify-content: space-between;
          li {
            font-size: 14px;
            font-weight: 400;

            list-style: none;
          }
          li.your-order-shipping {
            font-size: 16px;
            font-weight: 400;

            color: $black;
          }
        }
      }
      .your-order-total {
        margin: 18px 0 33px;
        padding: 17px 0 19px;

        border-top: 1px solid $grey--eight;
        border-bottom: 1px solid $grey--eight;
        ul {
          display: flex;
          align-items: center;
          justify-content: space-between;
          li.order-total {
            font-size: 18px;
            font-weight: 500;

            color: $black;
          }
          li {
            font-size: 16px;
            font-weight: 500;

            list-style: outside none none;

            color: $theme-color--default;
          }
        }
      }
    }
  }

  .place-order > a {
    font-weight: 500;
    line-height: 1;

    z-index: 9;

    display: block;

    padding: 18px 20px;

    text-align: center;
    letter-spacing: 1px;
    text-transform: uppercase;

    color: $white;
    border-radius: 50px;
    background-color: $theme-color--default;
    &:hover {
      background-color: #333;
    }
  }
}

#payment-message {
  color: rgb(105, 115, 134);
  font-size: 16px;
  line-height: 20px;
  padding-top: 12px;
  text-align: center;
}

#payment-element {
  margin-bottom: 24px;
}

/* Buttons and links */
button {
  background: #5469d4;
  font-family: Arial, sans-serif;
  color: #ffffff;
  border-radius: 4px;
  border: 0;
  padding: 12px 16px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  display: block;
  transition: all 0.2s ease;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
  width: 100%;
}

button:hover {
  filter: contrast(115%);
}

button:disabled {
  opacity: 0.5;
  cursor: default;
}

/* spinner/processing state, errors */
.spinner,
.spinner:before,
.spinner:after {
  border-radius: 50%;
}

.spinner {
  color: #ffffff;
  font-size: 22px;
  text-indent: -99999px;
  margin: 0px auto;
  position: relative;
  width: 20px;
  height: 20px;
  box-shadow: inset 0 0 0 2px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}

.spinner:before,
.spinner:after {
  position: absolute;
  content: "";
}

.spinner:before {
  width: 10.4px;
  height: 20.4px;
  background: #5469d4;
  border-radius: 20.4px 0 0 20.4px;
  top: -0.2px;
  left: -0.2px;
  -webkit-transform-origin: 10.4px 10.2px;
  transform-origin: 10.4px 10.2px;
  -webkit-animation: loading 2s infinite ease 1.5s;
  animation: loading 2s infinite ease 1.5s;
}

.spinner:after {
  width: 10.4px;
  height: 10.2px;
  background: #5469d4;
  border-radius: 0 10.2px 10.2px 0;
  top: -0.1px;
  left: 10.2px;
  -webkit-transform-origin: 0px 10.2px;
  transform-origin: 0px 10.2px;
  -webkit-animation: loading 2s infinite ease;
  animation: loading 2s infinite ease;
}

@keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

// @media only screen and (max-width: 600px) {
//   form {
//     width: 80vw;
//     min-width: initial;
//   }
// }

/*=====  End of checkout  ======*/
