/*=============================================
=            Common            =
=============================================*/

.bg-img {
  background-repeat: no-repeat;
  background-size: cover;
}

.border-bottom {
  border-bottom: 1px solid $grey--seven !important;
  &--medium {
    border-bottom: 2px solid $grey--seven !important;
  }
  &--thick {
    border-bottom: 3px solid $grey--seven !important;
  }
}

.body-wrapper {
  &.active-overlay {
    overflow: hidden;
    &:before {
      position: fixed;
      z-index: 999;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      content: "";

      background: transparent;
    }
  }
}

.alert-error {
  padding: 1rem;
  background: rgba(255, 0, 0, 0.2);
  margin: 1rem 0;
  border-radius: 4px;
}

.alert-success {
  padding: 1rem;
  background: rgba(0, 197, 105, 0.2);
  margin: 1rem 0;
  border-radius: 4px;
}

/*=====  End of Common  ======*/
