/*=============================================
=            category slider            =
=============================================*/

.category-item {
  text-align: center;
  cursor: pointer;
  &__image {
    position: relative;

    width: 60px;
    height: 60px;
    margin: 0 auto;
    margin-bottom: 15px;

    border-radius: 50%;
    background-color: $white;
    box-shadow: 4px 4px 4px rgb(36 36 36 / 30%);
    overflow: hidden;

    img {
      position: absolute;
      height: 100%;
      object-fit: cover;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      transform: scale(1.1);
    }

    a {
      position: absolute;
      top: 50%;
      left: 50%;

      display: inline-block;

      transform: translate(-50%, -50%);
    }
  }
  &__title {
    a {
      line-height: 1;

      display: block;

      color: $black;
    }
  }
}

/*=====  End of category slider  ======*/
