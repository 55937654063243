/*=============================================
=            receipt            =
=============================================*/

/* receipt header */

.receipt {
  &__header {
    padding: 16px;

    h1,
    p {
      text-align: center;
    }

    h1 {
      color: $theme-color--default;
      margin: 16px 0;
    }

    p {
      font-size: 16px;
    }

    &_qr {
      display: flex;
      justify-content: center;
      align-items: center;

      margin: 16px 0;
    }
  }

  &__content {
    &_thankyou {
      background-color: $them-color--black;

      padding: 16px;

      color: $white;
      text-align: center;

      h2 {
        font-size: 20px;
        color: $white;
        margin: 8px 0;
      }
    }
    &_items {
      padding: 16px;

      &_order-top {
        ul {
          display: flex;
          justify-content: space-between;
          li {
            font-size: 16px;
            font-weight: 500;

            list-style: outside none none;

            color: $black;
          }
        }
      }

      &_order-middle {
        margin: 16px 0 24px;
        padding: 19px 0 18px;

        ul {
          li {
            display: flex;
            justify-content: space-between;

            margin: 0 0 10px;
            border: 2px dashed rgba(0, 0, 0, 0.1);
            border-width: 0;
            border-bottom-width: px;
            padding: 4px 0;
          }
        }
      }
      &_order-bottom {
        ul {
          display: flex;
          align-items: center;
          justify-content: space-between;
          li {
            font-size: 14px;
            font-weight: 400;

            list-style: none;
          }
          li.your-order-shipping {
            font-size: 16px;
            font-weight: 400;

            color: $black;
          }
        }
      }
      &_order-total {
        margin: 18px 0 33px;
        padding: 17px 0 19px;

        border-top: 1px solid $grey--eight;
        border-bottom: 1px solid $grey--eight;
        ul {
          display: flex;
          align-items: center;
          justify-content: space-between;
          li.order-total {
            font-size: 18px;
            font-weight: 500;

            color: $black;
          }
          li {
            font-size: 16px;
            font-weight: 500;

            list-style: outside none none;

            color: $theme-color--default;
          }
        }
      }
      &_order-payment {
        ul {
          display: flex;
          align-items: center;
          justify-content: space-between;

          li {
            font-size: 16px;
            font-weight: 500;
            text-transform: uppercase;
            list-style: outside none none;
          }
        }
      }

      &_taxable-items {
        p {
          margin: 16px 0 0;
        }
        ul {
          display: flex;
          align-items: center;
          justify-content: space-between;

          color: $grey;
        }
      }

      &_close-button {
        margin: 24px 0 0;

        a {
          font-size: 16px;
          line-height: 1;
          text-align: center;

          width: 100%;
          padding: 15px 0;

          color: $white;
          border: none;
          background: none;
          background-color: $theme-color--default;
        }
      }
    }
  }
}

/*=====  End of receipt  ======*/
