/*=============================================
=            scan            =
=============================================*/

/* scan header */

.scan__wrapper {
  height: calc(100vh - 70px - 86px);
  overflow-y: hidden;
  position: relative;

  @media #{$extra-extra-small-mobile} {
    height: calc(100vh - 70px - 61px);
  }

  &_checkout_button {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;

    background-color: $theme-color--default;
    color: $white;

    padding: 8px 16px;
    margin: 4px auto;
    border-radius: 8px;
    width: 80%;

    display: flex;
    align-items: center;
    gap: 16px;

    z-index: 1;

    cursor: pointer;

    transition: $transition--default;

    transform: translateY(200%);

    &.active {
      transform: translateY(0);
    }

    .price {
      display: flex;
      flex-direction: column;
      gap: 2;

      font-size: 16px;

      span:nth-child(1) {
        font-weight: 500;
        font-size: 20px;
        line-height: 1;
      }
    }

    &:hover {
      filter: brightness(110%);
      color: $white;
    }
  }

  &_borders {
    position: absolute;
    border: 5px solid rgba(95, 255, 36, 0.3);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    &.bottom__right {
      bottom: 15%;
      top: 60%;
      right: 10%;
      left: 70%;
      border-top-width: 0;
      border-left-width: 0;
    }

    &.bottom__left {
      bottom: 15%;
      top: 60%;
      right: 70%;
      left: 10%;
      border-top-width: 0;
      border-right-width: 0;
    }

    &.top__right {
      bottom: 60%;
      top: 15%;
      right: 10%;
      left: 70%;
      border-bottom-width: 0;
      border-left-width: 0;
    }

    &.top__left {
      bottom: 60%;
      top: 15%;
      right: 70%;
      left: 10%;
      border-bottom-width: 0;
      border-right-width: 0;
    }
  }
  &__pulse {
    position: absolute;
    top: 15%;
    bottom: 10%;
    left: 10%;
    right: 10%;
    animation: pulse 2s infinite;
  }

  &_volume {
    position: absolute;
    top: 4%;
    right: 8%;
    z-index: 2;
    padding: 8px;

    cursor: pointer;
    transition: $transition--default;

    border: 0;
    border-radius: 50%;
    width: 42px;
    height: 42px;

    display: flex;
    justify-content: center;
    align-items: center;

    background-color: rgba(0, 0, 0, 0.2);
  }

  video {
    object-fit: cover;
  }

  .slide {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: all 0.6s;

    resize: both;

    transform: translateY(calc(100% - 50px));

    &.active {
      transform: translateY(0);
    }

    &__bar {
      width: 100%;
      height: 50px;

      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      padding-bottom: 8px;

      p {
        color: #fff;

        animation: pulse 2s infinite;
      }

      span {
        width: 250px;
        height: 5px;
        padding: 5px;
        background-color: rgba(255, 255, 255, 0.6);
        border-radius: 100px;
      }
    }

    &__menu {
      width: 100%;

      background: white;
      height: 60vh;
      overflow: auto;
      position: relative;

      table {
        width: 100%;

        table-layout: fixed;
        border-collapse: collapse;

        thead {
          position: sticky;
          position: -webkit-sticky;
          position: -moz-sticky;
          position: -ms-sticky;
          position: -o-sticky;

          top: 0;
          left: 0;
          right: 0;

          background-color: $grey--eight;
          border-radius: 8px;

          box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
          z-index: 1;

          border: 1px solid transparent;
          border-radius: 25px;
        }

        tbody tr:nth-child(odd) {
          background-color: $grey--ten;
        }

        th,
        td {
          padding: 12px 4px;
          text-align: center;

          .main-price {
            text-decoration: line-through;

            color: $red--two;
          }
        }

        .rounded__left {
          border: 1px solid transparent;
          border-top-left-radius: 25px;
        }

        .rounded__right {
          border: 1px solid transparent;
          border-top-right-radius: 25px;
        }

        .item__quantity {
          position: relative;

          .qtybutton {
            font-size: 16px;

            position: absolute;

            float: inherit;

            width: 30px;
            margin: 0;

            cursor: pointer;
            transition: all 0.3s ease 0s;
            text-align: center;

            color: $black--five;
            border: none;
            background: none;

            display: flex;
            justify-content: center;
            align-items: center;
          }
          .dec.qtybutton {
            left: 0;

            height: 30px;

            border-right: 1px solid $grey--eight;
          }
          .inc.qtybutton {
            right: 0;

            height: 30px;

            border-left: 1px solid $grey--eight;
          }
          input.cart-plus-minus-box {
            font-size: 14px;

            float: left;

            width: 100%;
            height: 30px;

            align-self: center;
            margin: 0;
            padding: 0;

            text-align: center;

            color: $black--five;
            border: 1px solid $grey--eight;
            background: transparent none repeat scroll 0 0;
          }
        }

        .price {
          font-weight: 500;
          line-height: 1;

          color: $theme-color--default;
        }
      }
    }

    &__footer {
      width: 100%;
      background-color: white;

      display: flex;
      align-items: stretch;
      justify-content: space-between;

      padding: 8px;

      box-shadow: 0px -2px 8px rgba(0, 0, 0, 0.2);

      z-index: 1;

      &_total_price {
        width: 100%;

        h5 {
          font-weight: 400;
          font-size: 16px;
        }

        p {
          font-weight: 500;
          font-size: 20px;
          color: $theme-color--default;
        }
      }

      a {
        font-size: 16px;
        text-align: center;

        width: 80%;
        padding: $button-padding;

        color: $white;
        border: none;
        background: none;
        background-color: $theme-color--default;
      }
    }
  }
}

@keyframes pulse {
  0% {
    opacity: 0;
  }

  70% {
    opacity: 0.5;
  }

  100% {
    opacity: 0;
  }
}

@keyframes inside-burst {
  0% {
    opacity: 0;
  }

  70% {
    opacity: 0.5;
  }

  100% {
    opacity: 0;
  }
}
/*=====  End of scan  ======*/
